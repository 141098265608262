<template>
  <el-dialog
    title="变更记录"
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
    class="dialog"
    center
  >
    <div class="changeRecord">
      <div class="title-from">

        <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="from"
      @Refresh="Refresh"
      @search="search"
    >
      
    </Filtersearch>

      </div>
      <div class="table-flex">
        <el-table
          :data="table_list"
          height="100%"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        >
          <el-table-column prop="logisticsName" align="center" label="城市仓">
          </el-table-column>
          <el-table-column
            prop="beforeMerchantName"
            align="center"
            label="修改前费率"
          >
          </el-table-column>
          <el-table-column
            prop="afterMerchantName"
            align="center"
            label="修改后费率"
          >
          </el-table-column>
          <el-table-column prop="createdAt" align="center" label="修改时间">
          </el-table-column>
          <el-table-column prop="opName" align="center" label="修改人">
          </el-table-column>
          <el-table-column prop="reason" align="center" label="修改原因">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">关闭</el-button>
    </span>
  </el-dialog>
</template>
    
    <script>
    import Filtersearch from "@/components/Filtersearch.vue";
export default {
  name: "changeRecord",
  props: {},
  components: {
    Filtersearch,
  },
  data() {
    return {
      dialogVisible: false,
      Filtersearchlist: [ {
          type: "select",
          name: "城市仓",
          clearable: false,
          filterable: true,
          model: "logistics_id",
          placeholder: "请选择城市仓",
          label: "name",
          value: "id",
          selectoption: [],
          selectoptionname: "logisticsList",
        }, {
          type: "input",
          name: "操作人",
          clearable: true,
          model: "op_name",
          placeholder: "请输入操作人",
        },{
          type: "daterange",
          name: "操作时间",
          clearable: true,
          model: "payment_edit",
          startmodel: "payment_edit_start_time",
          endmodel: "payment_edit_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },],
      table_list: [],
      from: {
        page: 1, //	number	页码
        pageSize: 99999999, //number	页大小
        logistics_id: "", //number	城市仓id
        payment_edit_start_time: "", //	string	筛选项-开始时间
        payment_edit_end_time: "", //	string	筛选项-借宿时间
        payment_edit:[],
        op_name: "", //	string	筛选项-操作人
      },
      ids:'',
      cityid:''
    };
  },
  methods: {
    onInitData(e,id) {
        this.cityid=id;
        this.table_list=[]
      this.from.logistics_id = e.id;
      this.ids=e.id;
      this.dialogVisible = true;
      this.getsearch();
      this.hqlist();
    },
    getsearch() {
      this.$api.payment
        .configsearch({ logistics_business_id:this.cityid })
        .then((res) => {
          this.Filtersearchlist.forEach((el) => {
            if (el.type == "select" && el.selectoptionname) {
              el.selectoption = [ ...res.data[el.selectoptionname]];
            }
          });
        });
    },
    Refresh() {
        
        Object.assign(this.$data.from, this.$options.data().from);
        this.from.logistics_id=this.ids;
      this.hqlist();
    },
    search(e) {
      
      this.from = e;
      this.hqlist();
    },
    hqlist() {
      this.$api.payment.configloglist(this.from).then((res) => {
        this.table_list = res.data.data;
      });
    },
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
  created() {},
};
</script>
    
    <style lang="scss" scoped>
.changeRecord {
  padding: 20px;
  box-sizing: border-box;
  height: 500px;
  display: flex;
  flex-direction: column;
  .table-flex {
    flex: 1;
  }
  .title-from{
    margin-bottom: 20px;
  }
}
</style>
    