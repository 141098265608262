var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "editDialog" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "城市仓" } }, [
                    _c("div", [_vm._v(" " + _vm._s(_vm.logistics_name) + " ")]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "费率类型", prop: "merchant_code" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择费率类型",
                            clearable: "",
                          },
                          model: {
                            value: _vm.formData.merchant_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "merchant_code", $$v)
                            },
                            expression: "formData.merchant_code",
                          },
                        },
                        _vm._l(_vm.tag_list, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.value, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "修改原因", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "128",
                          type: "textarea",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formData.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "reason", $$v)
                          },
                          expression: "formData.reason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }