var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "变更记录",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "changeRecord" }, [
            _c(
              "div",
              { staticClass: "title-from" },
              [
                _c("Filtersearch", {
                  ref: "Filtersearchref",
                  attrs: { list: _vm.Filtersearchlist, fromdata: _vm.from },
                  on: { Refresh: _vm.Refresh, search: _vm.search },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-flex" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.table_list,
                      height: "100%",
                      border: true,
                      "header-cell-style": {
                        color: "#333333",
                        background: "#EFF6FF",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "logisticsName",
                        align: "center",
                        label: "城市仓",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "beforeMerchantName",
                        align: "center",
                        label: "修改前费率",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "afterMerchantName",
                        align: "center",
                        label: "修改后费率",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdAt",
                        align: "center",
                        label: "修改时间",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "opName",
                        align: "center",
                        label: "修改人",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reason",
                        align: "center",
                        label: "修改原因",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }