<template>
  <el-dialog
    :title="title"
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
    class="dialog"
    center
  >
    <div class="editDialog">
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="城市仓">
          <div>
            {{ logistics_name }}
          </div>
        </el-form-item>
        <el-form-item label="费率类型" prop="merchant_code">
          <el-select
            v-model="formData.merchant_code"
            placeholder="请选择费率类型"
            clearable=""
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="(item, index) in tag_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="修改原因" prop="reason">
          <el-input
            v-model="formData.reason"
            maxlength="128"
            type="textarea"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>
  
  <script>
export default {
  name: "editDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      ids: "",
      title:'编辑',
      tag_list: [],
      formData: {
        logistics_id: "", //	number	城市仓id
        logistics_ids: [],
        merchant_code: "", //	string	费率
        reason: "", //	string原因
      },
      rules: {
        reason: [
          { required: true, message: "请输入修改原因", trigger: "blur" },
        ],
        merchant_code: [
          { required: true, message: "请选费率", trigger: "change" },
        ],
      },
      all: false,
    };
  },
  methods: {
    onInitData(e, id) {
      this.ids = id;
      this.formData.logistics_id = e.id;
      this.formData.merchant_code = e.merchantCode;
      this.formData.reason = "";
      this.logistics_name = e.name;
      this.dialogVisible = true;
      this.all = false;
      this.title='编辑';
      this.getsearch(id);
    },
    onInitDataAll(e, id) {
      this.ids = id;
      this.formData.logistics_ids = e.logistics_ids;
      this.formData.merchant_code = "";
      this.formData.reason = "";
      this.logistics_name = e.name;
      this.dialogVisible = true;
      this.all = true;
      this.title='批量修改';
      this.getsearch(id);
    },
    getsearch(id) {
      this.$api.payment
        .configsearch({ logistics_business_id: id })
        .then((res) => {
          console.log(res, "11111111111111111");
          this.tag_list = res.data.paymentConfigList;
        });
    },
    contrast() {
      this.$api.payment
        .salescycle({ logistics_business_id: this.ids })
        .then((res) => {
          if (res.data.isSalesCycle) {
            this.$confirm(
              "在售期修改费率会影响用户正常支付，是否确认修改",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.getconfigupdate();
              })
              .catch(() => {});
          } else {
            this.getconfigupdate();
          }
        });
    },
    getconfigupdate() {
      if (this.all) {
        this.$api.payment.configbatchupdate(this.formData).then((res) => {
          this.dialogVisible = false;
          this.$message.success("编辑成功");
          this.$emit("tablechangeupdate", true);
        });
      } else {
        this.$api.payment.configupdate(this.formData).then((res) => {
          this.dialogVisible = false;
          this.$message.success("编辑成功");
          this.$emit("tablechangeupdate", true);
        });
      }
    },
    onHandleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.contrast();
        } else {
          this.loadingSubmit = false;
        }
      });
    },
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
  created() {},
};
</script>
  
  <style lang="scss" scoped>
.editDialog {
  padding: 20px;
  box-sizing: border-box;
}
</style>
  